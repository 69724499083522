import React from "react"
import { graphql } from "gatsby"
import Layout from "../wrappers/Layout"
import Image from "../components/Image"
import RichText from "../components/RichText"

const AboutPage = ({ data, location }) => {

	const { 
		mission, 
		technologyTitle, 
		technologyCopy,
		technologyImage,
		featureImage,
		featureCopy,
		materialsTitle,
		materialsCopy,
		materialsImage			 
	} = data.allContentfulAboutPage.nodes[0]

	return (
		<Layout title={`About | Harrys London`} location={location}>
			<section className="innerx2 bg-gold">
				<div className="about-section no-p-margins">
					<RichText content={mission} />
				</div>
			</section>
			<section>
				<h1 className="page-gutters outerx4 align-center">{technologyTitle}</h1>
				<div className="about-section reverse">
					<div>
						<Image image={technologyImage?.large} />
					</div>
					<div>
						<RichText content={technologyCopy} />
					</div>
				</div>
			</section>
			<section className="bg-sand">
				<div className="about-section">
					<div>
						<Image image={featureImage?.large} />
					</div>
					<div>
						<RichText content={featureCopy} />
					</div>
				</div>
			</section>
			<section>
				<h1 className="page-gutters outerx4 align-center">{materialsTitle}</h1>
				<div className="about-section reverse">
					<div>
						<Image image={materialsImage?.large} />
					</div>
					<div>
						<RichText content={materialsCopy} />
					</div>
				</div>
			</section>
		</Layout>
	)
}

export default AboutPage

export const query = graphql`
	query {
		allContentfulAboutPage(limit: 1) {
			nodes {
				mission {
					raw
				}
				technologyTitle
				technologyCopy {
					raw
				}
				technologyImage {
					...ContentfulMediaFragment
				}
				featureImage {
					...ContentfulMediaFragment
				}
				featureCopy {
					raw
				}
				materialsTitle
				materialsCopy {
					raw
				}
				materialsImage {
					...ContentfulMediaFragment
				}
			}
		}
	}
`